.unreset {
  a {
    @apply text-blue-700 underline;
  }

  blockquote {
    @apply my-4 border-l-4 pl-4 py-2;
  }

  h1 {
    @apply text-4xl my-1;
  }

  h2 {
    @apply text-2xl my-1;
  }

  ul {
    @apply list-disc my-1 pl-10;
  }

  ol {
    @apply list-decimal my-4 pl-10;
  }

  ul,
  ol {
    ul {
      list-style-type: circle;
    }

    ul,
    ol {
      ul {
        list-style-type: square;
      }
    }
  }

  ul,
  ol {
    margin: 0;
  }
}